class ZIDXAccountUserCredit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountUserCreditContainer";
    }
    getMatchURL(){
        return "/account/users/credit";
    }
    render(){
        // console.log("user edit");
    }
}